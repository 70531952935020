<template>
  <div>
    <HEADER />
    <MENU />
    <div class="main">
      <div>
        <h2 class="title">作品登録/編集</h2>
        <el-form :model="form" ref="myForm" :rules="formRules">
          <table class="table table-bordered tablebox-t5 textarea-detile">
            <tbody>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>作品ID​
                </th>
                <td>
                  <el-form-item label="">
                    <el-input
                      v-model="form.id"
                      type="text"
                      placeholder="XXXXXXXX"
                      disabled
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>作品タイトル
                </th>
                <td>
                  <el-form-item label="" prop="name">
                    <el-input
                      v-model="form.name"
                      type="text"
                      placeholder="作品タイトルを入力して下さい。​"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>リード
                </th>
                <td>
                  <el-form-item label="" prop="sub_title">
                    <el-input
                      v-model="form.sub_title"
                      type="text"
                      placeholder="作品タイトルを入力して下さい。"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160"><span class="style_red">*</span>ストーリー</th>
                <td>
                  <el-form-item label="" prop="story">
                    <el-input
                      v-model="form.story"
                      type="textarea"
                      :autosize="{ minRows: 10 }"
                    ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>サムネイル画像
                  <br />（キービジュアル用）
                </th>
                <td>
                  <el-form-item label="" prop="image">
                    <div class="upload-file">
                      <div>
                        W:{{ width }}px H:{{ height }}px（{{ type_file }}）
                      </div>
                      <el-upload
                        :before-upload="beforeUpload"
                        class="upload-demo"
                        :show-file-list="false"
                        action="null"
                        accept="image/png, image/jpeg, image.jpg"
                      >
                        <el-button type="primary"
                          >ファイルを指定してアップロード
                        </el-button>
                      </el-upload>
                    </div>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>TOPカルーセル表示
                </th>
                <td>
                  <el-form-item prop="isDeleted">
                    <BaseRadio
                        :value="form.is_publish_top"
                        :options="publishOptions"
                        keys="is_publish_top"
                        @change-radio="(name, value) => handleOnChange(name, value)"
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">表示順</th>
                <td>
                  <el-form-item prop="orderNo">
                    <BaseInput
                        :value="form.order_no"
                        keys="order_no"
                        type="number"
                        placeholder="表示順を半角数字で入力してください"
                        @keypress.native="(event) => handleOnKeyPress(event)"
                        @input-change="(name, value) => handleOnChange(name, value)"
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">
                  <span class="style_red">*</span>TOPに第1話表示
                </th>
                <td>
                  <el-form-item prop="isDeleted">
                    <BaseRadio
                        :value="form.is_publish_episode_top"
                        :options="publishOptions"
                        keys="is_publish_episode_top"
                        @change-radio="(name, value) => handleOnChange(name, value)"
                    />
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <th width="160">第1話表示順</th>
                <td>
                  <el-form-item prop="orderNo">
                    <BaseInput
                        :value="form.episode_order_no"
                        keys="episode_order_no"
                        type="number"
                        placeholder="表示順を半角数字で入力してください"
                        @keypress.native="(event) => handleOnKeyPress(event)"
                        @input-change="(name, value) => handleOnChange(name, value)"
                    />
                  </el-form-item>
                </td>
              </tr>
            </tbody>
          </table>
        </el-form>
        <div class="btnbox text-center mt50 mb100">
          <button class="btn btn-red ml20" @click="submit">登録する</button>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import HEADER from "@/components/Header";
import MENU from "@/components/MENU";
import { api } from "@/api";
import axios from "axios";
import { PUBLISHING_DRAMA } from "@/constants";
import BaseRadio from "@/components/BaseRadio.vue";
import BaseInput from "@/components/BaseInput.vue";

export default {
  name: "drama",
  components: {
    BaseInput,
    BaseRadio,
    HEADER,
    MENU,
  },
  props: {},
  data() {
    return {
      form: {
        id: "",
        name: "",
        sub_title: "",
        story: "",
        image: null,
        is_publish_top: 1,
        is_publish_episode_top: 1,
        order_no: null,
        episode_order_no: null,
      },
      formRules: {
        name: [
          {
            required: true,
            message: "作品タイトルを入力してください。",
            trigger: "blur",
          },
          {
            max: 255,
            message: "最大255文字まで入力できます。",
            trigger: "blur",
          },
        ],
        sub_title: [
          {
            required: true,
            message: "作品タイトルを入力してください。",
            trigger: "blur",
          },
        ],
        story: [
          {
            required: true,
            message: "ストーリー​を入力してください。",
            trigger: "blur",
          },
          {
            max: 5000,
            message: "最大255文字まで入力できます。",
            trigger: "blur",
          },
        ], 
      },
      id: this.$router.history.current.params.id,
      type_file: "jpg, png, jpeg",
      width: 0,
      height: 0,
      file: null,
      publishOptions: PUBLISHING_DRAMA,
      characterNotEnter: ["-", "e", "+", ".", ","],
    };
  },
  watch: {
    "form.image"() {
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(this.form.image);

      reader.onload = function (event) {
        let img = new Image();
        img.src = event.target.result;

        img.onload = function () {
          that.width = this.width;
          that.height = this.height;
        };
      };
    },
  },
  computed: {
    url: function () {
      return `${process.env.VUE_APP_URL}/dramas/${this.id}`;
    },
  },
  created() {
    this.show();
  },
  methods: {
    handleOnChange(name, value){
      this.form[name] = value;
    },
    handleOnKeyPress(event) {
      if (this.characterNotEnter.includes(event.key)) event.preventDefault();
    },
    async submit() {
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          return;
        }
      });

      try {
        let formData = this.formData();

        await axios.post(this.url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("authToken"),
          },
        });

        this.$router.push({ name: "drama-list" });
      } catch (error) {
        console.error("Error", error);
      }
    },
    beforeUpload(file) {
      const isImage = file.type.startsWith("image/");
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

      if (!isImage) {
        this.$message.error("画像ファイルのみを受け付けます");
        return false;
      }

      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        this.$message.error(
          "ファイル形式が無効です。許可されている形式は：jpg、jpeg、png、gifです"
        );
        return false;
      }

      this.form.image = file;
      this.type_file = file.name.split('.').pop();
      this.$refs['myForm'].clearValidate('image')

      return true;
    },
    async show() {
      try {
        const res = await api.showDrama(this.id);

        this.form.id = res.data.id;
        this.form.name = res.data.name;
        this.form.sub_title = res.data.sub_title;
        this.form.story = res.data.story;
        this.file = res.data.image;
        this.form.is_publish_top = res.data.is_publish_top ? 1 : 0;
        this.form.is_publish_episode_top = res.data.is_publish_episode_top ? 1 : 0;
        this.form.order_no = res.data.order_no;
        this.form.episode_order_no = res.data.episode_order_no;

        this.setPropertyImage(res.data.image_url);
      } catch (e) {
        console.error("Error", e);
      }
    },
    formData() {
      let formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("sub_title", this.form.sub_title);
      formData.append("story", this.form.story);
      formData.append("image", this.form.image);
      formData.append("is_publish_top", this.form.is_publish_top);
      formData.append("is_publish_episode_top", this.form.is_publish_episode_top);
      formData.append("order_no", this.form.order_no);
      formData.append("episode_order_no", this.form.episode_order_no);
      formData.append("_method", "PUT");

      return formData;
    },
    setPropertyImage(file) {
      let that = this;
      let img = new Image();
      img.src = file;
      this.type_file = file.split('.').pop();

      img.onload = function () {
        that.width = this.width;
        that.height = this.height;
      };
    },
  },
};
</script>
      
<style scoped lang="scss">
.upload-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnbox {
  width: 100%;
}

.el-form-item {
  margin-bottom: 0 !important;
  padding: 1.2rem;
}
</style>
